<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-btn icon>
        <v-icon>mdi-airplane</v-icon>
      </v-btn>
      <v-toolbar-title>onBoarding</v-toolbar-title>
      <v-spacer />
      <v-text-field
        class="mr-2"
        v-model="search"
        append-icon="mdi-magnify"
        label="Klasse oder Teil-Klasse"
        clearable
        single-line
        hide-details
        id="search"
      ></v-text-field>
      <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tabs-slider color="error"></v-tabs-slider>
          <v-tab :to="{ name: 'OnBoardingSchoolClasses' }">
            Schulklassen
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <router-view :search="search" />
  </v-container>
</template>

<script>
export default {
  name: 'OnBoarding',

  data() {
    return {
      search: '',
    };
  },
};
</script>